import { Suspense, useEffect } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthHOC from "./components/HOC/AuthHOC";
import UserGate from "./components/HOC/UserGate";
import { OFFICER_ROUTES, ROUTES, USER_ROUTES } from "./config/nav";
import Redirector from "./components/HOC/Redirector";
import LoginPage from "./pages/LoginPage/LoginPage";
import LoginRedirector from "./components/HOC/LoginRedirector";
import ApproverHomePage from "./pages/ApproverHomePage";
import OnSiteApprovalPage from "./pages/OnSiteApprovalPage";
import HomePage from "./pages/HomePage";
import PickSchedulePage from "./pages/PickSchedulePage";
import ReservationFormPage from "./pages/ReservationFormPage";
import ListReservationPage from "./pages/ListReservationPage";
import DetailReservationPage from "./pages/DetailReservationPage";
import OnSiteReservationFormPage from "./pages/OnSiteReservationFormPage";
import AdminChannelListPage from "./pages/AdminChannelListPage";
import AdminScheduleListPage from "./pages/AdminPage/Schedule/AdminScheduleListPage";
import AdminManageAccountPage from "./pages/AdminManageAccountPage";
import { useAppCookies, useLogin } from "./hooks/auth";
import { useAppDispatch } from "./redux";
import WalkInReservationPage from "./pages/Officer/WalkinReservationPage";
import WalkinDetailPage from "./pages/Officer/WalkinDetailPage";
import { getUser } from "./api/user/getUser";
import { storeUserData } from "./redux/UserStore/userstore";
import AdminScheduleChannelListPage from "./pages/AdminPage/Schedule/AdminScheduleChannelListPage";
import DetailGuestReservationPage from "./pages/Guest/DetailGuestReservationPage";
import DetailGuestWalkinReservationPage from "./pages/Guest/DetailGuestWalkinReservationPage";
import ForgotPasswordSendEmailPage from "./pages/ForgotPasswordSendUsernamePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ForgotPasswordTokenRedirectorPage from "./pages/ForgotPasswordTokenRedirectorPage";
import ExpiredLinkResendEmailPasswordPage from "./pages/ExpiredLinkResendUsernamePasswordPage";
import ListWarehousePage from "./pages/AdminPage/Warehouse/ListWarehousePage";
import ListDockWarehousePage from "./pages/AdminPage/Warehouse/ListDockWarehousePage";

function App() {
  const [{ jwt }] = useAppCookies(["jwt"]);
  const login = useLogin();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const ac = new AbortController();
    if (jwt) {
      login().then(({ id, ...restLoginRes }) =>
        getUser({ id: id }).then(({ ...restUser }) => {
          let dataUser = restUser.data.data.entities[0];
          dispatch(storeUserData({ ...dataUser, ...restLoginRes }));
        })
      );
    } else {
      return () => {
        ac.abort();
      };
    }
  }, [jwt, dispatch, login]);

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route
            path="/view/reservation/:qrid"
            element={<DetailGuestReservationPage />}
          />
          <Route
            path="/view/walkin-reservation/:qrid"
            element={<DetailGuestWalkinReservationPage />}
          />
          <Route path="/" element={<AuthHOC />}>
            <Route path={ROUTES.home} element={<Redirector />} />
            <Route
              path={ROUTES.approver}
              element={<UserGate allowedUsers={["approver"]} />}
            >
              <Route index element={<HomePage />} />
              <Route
                path={`${ROUTES.approver}/approval`}
                element={<ApproverHomePage />}
              />
              <Route
                path={`${ROUTES.approver}/:ticket`}
                element={<OnSiteApprovalPage />}
              />
            </Route>
            <Route
              path={ROUTES.officer}
              element={<UserGate allowedUsers={["officer"]} />}
            >
              <Route index element={<HomePage />} />
              <Route
                path={`${ROUTES.officer}/pickschedule`}
                element={<PickSchedulePage />}
              />
              <Route
                path={`${ROUTES.officer}/formreservation`}
                element={<ReservationFormPage />}
              />
              <Route
                path={`${ROUTES.officer}/onsiteform`}
                element={<OnSiteReservationFormPage />}
              />
              <Route
                path={`${ROUTES.officer}/listreservation`}
                element={<ListReservationPage />}
              />
              <Route
                path={`${ROUTES.officer}/detailreservation/:ticketId`}
                element={<DetailReservationPage />}
              />
              <Route
                path={`${ROUTES.officer}/:ticket`}
                element={<OnSiteApprovalPage />}
              />
              <Route
                path={OFFICER_ROUTES.walkInReservationList}
                element={<WalkInReservationPage />}
              />
            </Route>
            <Route
              path={ROUTES.user}
              element={<UserGate allowedUsers={["user"]} />}
            >
              <Route index element={<HomePage />} />
              <Route
                path={`${ROUTES.user}/pickschedule`}
                element={<PickSchedulePage />}
              />
              <Route
                path={`${ROUTES.user}/formreservation`}
                element={<ReservationFormPage />}
              />
              <Route
                path={`${ROUTES.user}/listreservation`}
                element={<ListReservationPage />}
              />
              <Route
                path={`${USER_ROUTES.reservationDetails}/:ticketId`}
                element={<DetailReservationPage />}
              />
            </Route>
            <Route
              path={ROUTES.superadmin}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <UserGate allowedUsers={["superadmin"]} />
                </Suspense>
              }
            >
              <Route index element={<HomePage />} />
              <Route
                path={`${ROUTES.superadmin}/channel`}
                element={<AdminChannelListPage />}
              />
              <Route
                path={`${ROUTES.superadmin}/schedule`}
                element={<AdminScheduleChannelListPage />}
              />
              <Route
                path={`${ROUTES.superadmin}/schedule/schedulegroupId/:schedulegroupId`}
                element={<AdminScheduleListPage />}
              />
              <Route
                path={`${ROUTES.superadmin}/account`}
                element={<AdminManageAccountPage />}
              />
              {/* <Route
                path={`${ROUTES.superadmin}/warehouse`}
                element={<ListWarehousePage />}
              />
              <Route
                path={`${ROUTES.superadmin}/warehouse/:channelId/dock/:warehouseId`}
                element={<ListDockWarehousePage />}
              /> */}
            </Route>

            <Route
              path={`${ROUTES.walkinTicket}/:ticketId`}
              element={<WalkinDetailPage />}
            />

            <Route
              path={`${ROUTES.ticket}/:ticketId`}
              element={<DetailReservationPage />}
            />
          </Route>
          <Route
            path={ROUTES.loginpage}
            element={
              <LoginRedirector>
                <LoginPage />
              </LoginRedirector>
            }
          />
          <Route
            path={`${ROUTES.forgotPassword}/sendemail`}
            element={<ForgotPasswordSendEmailPage />}
          />
          <Route
            path={`${ROUTES.forgotPassword}/resendemailexpired`}
            element={<ExpiredLinkResendEmailPasswordPage />}
          />
          <Route
            path={`${ROUTES.forgotPassword}/resetpassword`}
            element={<ForgotPasswordPage />}
          />
          <Route
            path={`${ROUTES.forgotPassword}/token/:token`}
            element={<ForgotPasswordTokenRedirectorPage />}
          />
        </Routes>
      </HashRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
