import React, {
  useState,
  useCallback,
  ChangeEvent,
  useRef,
  MutableRefObject,
} from "react";
import { Label, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { toast } from "react-toastify";
import { COLORS } from "../assets/theme";
import PageContainer from "../components/PageContainer/PageContainer";
import Header from "../components/atoms/Header";
import PureInputSelect from "../components/atoms/PureInputSelect";
import useChannelOptions from "../hooks/channels/useChannelOptions";
import { useGetVendorsOptionsQuery } from "../redux/VendorStore/vendorstoreAPI";
import {
  useAddPhotoWalkinReservationMutation,
  useCreateAWIReservationMutation,
} from "../redux/ReservationStore/walkinReservationStoreAPI";
import useMaterialOptions from "../hooks/materials/useMaterialOptions";
import { ChannelOpt } from "../types/channel";
import { AVendorOpt } from "../types/vendor";
import { AMaterialOpt } from "../types/material";
import useSchemaCreateWIReservation from "../hooks/channels/useSchemaCreateWIReservation";
import { ErrorMessageBackendDataShape } from "../types";
import ImageViewer from "../components/atoms/FileInput/ImageViewer";
import { shortDateFormat } from "../types/dateformat";
import { format } from "date-fns";
import useButtonStyles from "../hooks/styles/useButtonStyles";
import { BiPlus } from "react-icons/bi";
import { ButtonBase, makeStyles } from "@material-ui/core";
import {
  replaceInputTextContainer,
  replaceInputTextLicensePlate,
  replaceInputTextToNumberOnly,
} from "../tools";

interface Props {}

const useStyles = makeStyles(() => {
  return {
    container: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    textFormTitle: {
      fontFamily: "Raleway",
      fontSize: 12,
      position: "relative",
      fontWeight: 600,
    },
    mandatoryStar: {
      color: "red",
      fontSize: 24,
      verticalAlign: "middle",
      height: 0,
      position: "absolute",
      top: -8,
      margin: 0,
    },
    submitButtonContainer: {
      width: "100%",
      fontWeight: 600,
      fontSize: "1.5rem",
      padding: "1.6rem 0",
      letterSpacing: 0.65,
      color: "white",
      borderRadius: ".8rem",
      border: `1px solid`,
      background: COLORS.gradientBlueGreen_1,
      marginBottom: "1rem",
      "&:hover": {
        opacity: 0.7,
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        opacity: 0.4,
      },
    },
    input: {
      height: "32px",
    },
    formInput: {
      padding: "1rem 1.6rem",
      borderRadius: "4px",
      border: "1px solid #DDDDDD",
    },
  };
});

const OnSiteReservationFormPage: React.FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [createWalkIn] = useCreateAWIReservationMutation();
  const [selectedChannel, setSelectedChannel] = useState<ChannelOpt | null>(
    null
  );
  const [description, setDescription] = useState<string>("");
  const uploadPhotosRef = useRef() as MutableRefObject<HTMLInputElement>;
  const btn = useButtonStyles();
  const [selectedVendor, setSelectedVendor] = useState<AVendorOpt | null>(null);
  const [selectedTruck, setSelectedTruck] = useState<string>("");
  const [selectedPO, setSelectedPO] = React.useState<string>("");
  const [selectedPOLine, setSelectedPOLine] = React.useState<string>("");
  const [driverName, setDriverName] = useState<string>("");
  const [driverNumber, setdriverNumber] = useState<string>("");
  const [selectedMaterial, setSelectedMaterial] =
    React.useState<AMaterialOpt | null>(null);
  const [selectedDeliveryNote, setSelectedDeliveryNote] =
    React.useState<string>("");
  const [selectedShipmentNo, setSelectedShipmentNo] =
    React.useState<string>("");
  const [selectedContainer, setSelectedContainer] = React.useState<string>("");
  const { arr: channelList, isLoading: isLoadingChannel } = useChannelOptions({
    active: true,
  });
  const { data: vendorsList, isFetching: isFetchingVendor } =
    useGetVendorsOptionsQuery({});
  const { arr: materialOpts, isLoading: isLoadingMaterials } =
    useMaterialOptions({
      args: {
        channel: selectedChannel?.channel,
      },
    });
  const [photos, setPhotos] = React.useState<File[]>([]);
  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    let { files } = event.target;
    if (files) {
      let fileArr: File[] = [];
      for (let i = 0; i < files.length; i++) {
        const el = files[i];
        if (el.size < 1024000) {
          fileArr.push(el);
        } else {
          toast.error("Gagal Unggah Gambar! Ukuran file lebih dari 1024 kb.");
        }
      }
      setPhotos((prev) => {
        let newarr = prev.concat(fileArr);
        return newarr;
      });
    }
  };
  const [addPhotoWalkinReservation] = useAddPhotoWalkinReservationMutation();
  const { schema: validationSchema, isLoading: isLoadingSchema } =
    useSchemaCreateWIReservation(selectedChannel?.id ?? -1, {
      skip: typeof selectedChannel === "undefined",
    });
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const onsubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const fields = validationSchema.fields;

      const { statusPhoto } = selectedChannel || {};
      // whether the photo should be uploaded
      // after validation via yup schema
      const shouldUploadPhotos = statusPhoto?.active
        ? !statusPhoto?.optional
          ? true
          : !!photos.length
        : false;
      let data: {
        [key in keyof typeof fields]?: any;
      } = {
        channelId: selectedChannel?.id,
        date: format(new Date(), shortDateFormat),
        nopol: selectedTruck,
        description: description,
      };

      selectedChannel?.statusVendor.active &&
        Object.assign(data, { vendcode: selectedVendor?.vendcode });
      selectedChannel?.statusDriverName.active &&
        Object.assign(data, { drivername: driverName });
      !!selectedChannel?.statusDriverPhoneNumber?.active &&
        Object.assign(data, { driverPhoneNumber: driverNumber });
      selectedChannel?.statusMaterial.active &&
        Object.assign(data, { materialId: selectedMaterial?.value });
      selectedChannel?.statusDeliveryNote.active &&
        Object.assign(data, { deliveryNote: selectedDeliveryNote });
      selectedChannel?.statusPo.active &&
        Object.assign(data, {
          ponum: selectedPO,
          poline: parseInt(selectedPOLine),
        });
      selectedChannel?.statusContainer.active &&
        Object.assign(data, {
          container: selectedContainer,
        });
      selectedChannel?.statusShipmentNumber.active &&
        Object.assign(data, {
          shipmentNumber: selectedShipmentNo,
        });
      selectedChannel?.statusPhoto.active &&
        Object.assign(data, {
          images: photos,
        }); // for validation only.

      validationSchema
        .validate(data)
        .then((val) => {
          setIsSubmitting(true);
          delete val.images; // images will be uploaded in different endpoint
          createWalkIn(val)
            .unwrap()
            .then(async (res) => {
              const resultId = res?.data?.raw?.[0]?.id;

              if (shouldUploadPhotos) {
                const formData = new FormData();
                for (let i = 0; i < photos.length; i++) {
                  formData.append("images[]", photos![i]);
                }
                await addPhotoWalkinReservation({
                  id: resultId,
                  images: formData,
                })
                  .unwrap()
                  .then(() => {
                    if (photos.length > 0) {
                      toast.success("Berhasil menambahkan gambar.");
                    }
                  })
                  .catch(() => {
                    toast.error(`Gagal menambahkan gambar!`);
                  });
              }
              toast.success("Berhasil membuat reservasi walk-in!");
              navigate(`/reservation/walkin/${resultId}`);
            })
            .catch((e: ErrorMessageBackendDataShape) => {
              toast.error(`Gagal membuat reservasi walk-in! ${e.data.message}`);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        })
        .catch((e: yup.ValidationError) => {
          toast.error(
            `Gagal membuat reservasi walk-in! ${e.errors.join(", ")}`
          );
        });
    },
    [
      selectedVendor,
      addPhotoWalkinReservation,
      photos,
      selectedMaterial,
      selectedChannel,
      navigate,
      selectedTruck,
      driverName,
      selectedPO,
      selectedDeliveryNote,
      selectedShipmentNo,
      selectedContainer,
      validationSchema,
      createWalkIn,
      selectedPOLine,
      driverNumber,
    ]
  );

  return (
    <PageContainer
      headerContainerStyle={{
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
      mainContainerStyle={{ borderTopLeftRadius: 16, borderTopRightRadius: 16 }}
      headerNavigationContent={
        <div style={{ minHeight: "100vh", width: "100%" }}>
          <Header
            title={"Reservasi Walk-in Approval"}
            showBackButton={true}
            backButtonOnClick={() => navigate(-1)}
          />
          <div style={{ marginTop: 16 }}>
            <span style={{ color: "red", position: "relative", left: 12 }}>
              <span
                style={{
                  fontSize: 24,
                  position: "absolute",
                  top: -6,
                  left: -12,
                }}
              >
                *
              </span>{" "}
              Wajib Diisi
            </span>
          </div>
          <form
            style={{ flex: 1, marginTop: 16 }}
            onSubmit={onsubmit}
            id="reservationWalkInForm"
          >
            <div style={{ marginBottom: 16 }}>
              <Label className={classes.textFormTitle}>Channel</Label>
              <PureInputSelect
                isLoading={isLoadingChannel}
                options={channelList.sort((a, b) =>
                  a.description.localeCompare(b.description)
                )}
                value={selectedChannel}
                onChange={(value) => {
                  setSelectedChannel(value);
                }}
                placeholder="Pilih Channel"
              />
            </div>
            {selectedChannel && (
              <>
                {selectedChannel.statusVendor.active && (
                  <div style={{ marginBottom: 16 }}>
                    <Label className={classes.textFormTitle}>
                      Vendor{" "}
                      {!selectedChannel?.statusVendor.optional && (
                        <span className={classes.mandatoryStar}>*</span>
                      )}
                    </Label>
                    <PureInputSelect
                      isLoading={isFetchingVendor}
                      options={vendorsList}
                      placeholder="Pilih Vendor"
                      isSearchable
                      isClearable
                      isLargeDataList
                      value={selectedVendor}
                      onChange={(value) => {
                        setSelectedVendor(value);
                      }}
                    />
                  </div>
                )}
                <div style={{ marginBottom: 16 }}>
                  <Label className={classes.textFormTitle}>
                    No. Kendaraan{" "}
                    <span className={classes.mandatoryStar}>*</span>
                  </Label>
                  <Input
                    id="nopol"
                    className={classes.formInput}
                    name="nopol"
                    placeholder="No. Kendaraan"
                    required
                    value={selectedTruck}
                    onChange={(e) => {
                      setSelectedTruck(e.target.value);
                    }}
                    onInput={replaceInputTextLicensePlate}
                  />
                </div>
                {selectedChannel.statusDriverName.active && (
                  <div style={{ marginBottom: 16 }}>
                    <Label className={classes.textFormTitle}>
                      Pengemudi{" "}
                      {!selectedChannel?.statusDriverName.optional && (
                        <span className={classes.mandatoryStar}>*</span>
                      )}
                    </Label>
                    <Input
                      id="driver"
                      name="pengemudi"
                      placeholder="Pengemudi"
                      className={classes.formInput}
                      required={!selectedChannel.statusDriverName.optional}
                      value={driverName}
                      onChange={(e) => {
                        setDriverName(e.target.value);
                      }}
                    />
                  </div>
                )}
                {selectedChannel.statusDriverPhoneNumber?.active && (
                  <div style={{ marginBottom: 16 }}>
                    <Label className={classes.textFormTitle}>
                      Nomor HP Pengemudi{" "}
                      {!selectedChannel?.statusDriverPhoneNumber.optional && (
                        <span className={classes.mandatoryStar}>*</span>
                      )}
                    </Label>
                    <Input
                      id="driver"
                      name="Nomor HP Pengemudi"
                      placeholder="Nomor HP Pengemudi"
                      className={classes.formInput}
                      required={
                        !selectedChannel.statusDriverPhoneNumber.optional
                      }
                      value={driverNumber}
                      onInput={replaceInputTextToNumberOnly}
                      onChange={(e) => {
                        setdriverNumber(e.target.value);
                      }}
                    />
                  </div>
                )}
                {selectedChannel.statusMaterial.active && (
                  <div style={{ marginBottom: 16 }}>
                    <Label className={classes.textFormTitle}>
                      Jenis Barang{" "}
                      {!selectedChannel?.statusMaterial.optional && (
                        <span className={classes.mandatoryStar}>*</span>
                      )}
                    </Label>
                    <PureInputSelect
                      options={materialOpts}
                      value={selectedMaterial}
                      onChange={(val) => {
                        setSelectedMaterial(val);
                      }}
                      isDisabled={isSubmitting}
                      isLoading={isLoadingMaterials}
                      placeholder="Pilih Jenis Barang"
                      styles={{
                        control: (prev, { isDisabled }) => {
                          return {
                            ...prev,
                            backgroundColor: isDisabled
                              ? "#F2F2F2"
                              : "rgba(255, 255, 255, 1)",
                            borderRadius: "4px",
                            border: "0.5px solid #C4CDE0",
                          };
                        },
                        option: (prev, { isDisabled }) => {
                          return {
                            ...prev,
                            cursor: isDisabled ? "not-allowed" : "pointer",
                            backgroundColor: "white",
                            color: isDisabled ? "#687484" : "#000000",
                            "&:hover": {
                              backgroundColor: !isDisabled
                                ? COLORS.blue_1
                                : COLORS.light_gray_1,
                              color: !isDisabled ? "white" : "#687484",
                            },
                          };
                        },
                      }}
                    />
                  </div>
                )}
                {selectedChannel.statusPo.active && (
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ marginBottom: 16, flex: 1 }}>
                      <Label className={classes.textFormTitle}>
                        Nomor PO{" "}
                        {!selectedChannel?.statusPo.optional && (
                          <span className={classes.mandatoryStar}>*</span>
                        )}
                      </Label>
                      <Input
                        id="inputPo"
                        className={classes.formInput}
                        required={!selectedChannel.statusPo.optional}
                        name="inputPo"
                        type="number"
                        placeholder="Nomor PO"
                        value={selectedPO}
                        onChange={(e) => {
                          setSelectedPO(e.target.value);
                        }}
                      />
                    </div>
                    <div style={{ marginBottom: 16, flex: "0 0 50%" }}>
                      <Label className={classes.textFormTitle}>
                        PO Line{" "}
                        {!selectedChannel?.statusPo.optional && (
                          <span className={classes.mandatoryStar}>*</span>
                        )}
                      </Label>
                      <Input
                        id="inpuPoLine"
                        className={classes.formInput}
                        required={!selectedChannel.statusPo.optional}
                        name="inpuPoLine"
                        placeholder="PO Line"
                        type="number"
                        value={selectedPOLine}
                        onChange={(e) => {
                          setSelectedPOLine(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedChannel.statusDeliveryNote.active && (
                  <div style={{ marginBottom: 16 }}>
                    <Label className={classes.textFormTitle}>
                      Delivery Note{" "}
                      {!selectedChannel?.statusDeliveryNote.optional && (
                        <span className={classes.mandatoryStar}>*</span>
                      )}
                    </Label>
                    <Input
                      id="delivery_note"
                      className={classes.formInput}
                      name="delivery_note"
                      disabled={isSubmitting}
                      required={!selectedChannel.statusDeliveryNote.optional}
                      placeholder="Delivery Note"
                      value={selectedDeliveryNote}
                      onChange={(e) => {
                        setSelectedDeliveryNote(e.target.value);
                      }}
                    />
                  </div>
                )}
                {selectedChannel.statusShipmentNumber.active && (
                  <div style={{ marginBottom: 16 }}>
                    <Label className={classes.textFormTitle}>
                      Shipment No.{" "}
                      {!selectedChannel?.statusShipmentNumber.optional && (
                        <span className={classes.mandatoryStar}>*</span>
                      )}
                    </Label>
                    <Input
                      id="shipment_no"
                      className={classes.formInput}
                      name="shipment_no"
                      disabled={isSubmitting}
                      placeholder="Shipment No."
                      required={!selectedChannel.statusShipmentNumber.optional}
                      value={selectedShipmentNo}
                      onChange={(e) => {
                        setSelectedShipmentNo(e.target.value);
                      }}
                    />
                  </div>
                )}
                {selectedChannel.statusContainer.active && (
                  <div style={{ marginBottom: 16 }}>
                    <Label className={classes.textFormTitle}>
                      No Container{" "}
                      {!selectedChannel?.statusContainer.optional && (
                        <span className={classes.mandatoryStar}>*</span>
                      )}
                    </Label>
                    <Input
                      id="no_container"
                      className={classes.formInput}
                      name="no_container"
                      disabled={isSubmitting}
                      placeholder="No Container"
                      required={!selectedChannel.statusContainer.optional}
                      value={selectedContainer}
                      onChange={(e) => {
                        setSelectedContainer(e.target.value);
                      }}
                      onInput={replaceInputTextContainer}
                    />
                  </div>
                )}
                <div style={{ marginBottom: 16 }}>
                  <Label className={classes.textFormTitle}>Keterangan</Label>
                  <Input
                    id="description"
                    className={classes.formInput}
                    name="description"
                    disabled={isSubmitting}
                    placeholder="Keterangan..."
                    value={description}
                    style={{ fontWeight: 600, fontSize: 13 }}
                    type="textarea"
                    rows="5"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
                {selectedChannel?.statusPhoto.active && (
                  <div style={{ marginBottom: 16 }}>
                    <p
                      className={classes.textFormTitle}
                      style={{ marginBottom: 6 }}
                    >
                      Foto{" "}
                      {!selectedChannel?.statusPhoto.optional && (
                        <span className={classes.mandatoryStar}>*</span>
                      )}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                        alignItems: "center",
                      }}
                    >
                      <ButtonBase
                        className={btn.uploadPhotosButton}
                        onClick={() => uploadPhotosRef.current.click()}
                        type="button"
                        style={{ width: "100%" }}
                      >
                        <BiPlus
                          size={14}
                          style={{ marginBottom: 2, marginRight: 4 }}
                        />
                        <span style={{ fontWeight: 600, fontSize: 13 }}>
                          Unggah Gambar
                        </span>
                      </ButtonBase>
                      <input
                        id="image"
                        type="file"
                        name="image"
                        ref={uploadPhotosRef}
                        accept="image/*"
                        multiple
                        hidden
                        disabled={isSubmitting}
                        onChange={handleChangeImage}
                      />
                      <span
                        style={{
                          fontSize: 10,
                          marginBottom: 0,
                          fontStyle: "italic",
                        }}
                      >
                        Keterangan: Format (.jpg /.png), max 1024 kb
                      </span>
                    </div>
                    {!!photos.length && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 10,
                          overflowX: "auto",
                        }}
                      >
                        {photos.map((val, idx) => {
                          return (
                            <ImageViewer
                              key={idx}
                              file={val}
                              containerStyle={{
                                height: 100,
                                width: 100,
                              }}
                              withDeleteIcon
                              onDelete={() => {
                                let newList = photos.slice();
                                newList.splice(idx, 1);
                                setPhotos(newList);
                              }}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </form>
          <ButtonBase
            className={classes.submitButtonContainer}
            style={{ marginTop: 16 }}
            type="submit"
            disabled={!selectedChannel || isLoadingSchema || isSubmitting}
            form="reservationWalkInForm"
          >
            BUAT WALK-IN
          </ButtonBase>
        </div>
      }
      className={classes.container}
      withBackground
    ></PageContainer>
  );
};

export default OnSiteReservationFormPage;
