import React from "react";
import useSpecificChannel from "./useSpecificChannel";
import * as yup from "yup";

const useSchemaUpdateWalkinReservation = (channelId: number) => {
  const { channel, isLoading } = useSpecificChannel(channelId);
  return React.useMemo(() => {
    let {
      statusDriverPhoneNumber,
      statusDriverName,
      statusMaterial,
      statusPo,
      statusDeliveryNote,
      statusShipmentNumber,
      statusContainer,
      statusPhoto,
    } = channel ?? {};
    let nopol = yup.string();
    let drivername = yup.string();
    let driverPhoneNumber = yup.string();
    let materialId = yup.number();
    let ponum = yup.string();
    let deliveryNote = yup.string();
    let shipmentNumber = yup.string();
    let container = yup.string();
    // let uploadedPhoto = yup.array();
    let images = yup.array();
    /**
     * @todo update this schema for new field: Driver Number
     */
    let schema = yup.object().shape({
      nopol: nopol
        .test("len", "Nopol wajib diisi", (val) =>
          val ? val.length > 0 : false
        )
        .required("Nopol wajib diisi"),
      drivername:
        statusDriverName && statusDriverName.active
          ? statusDriverName.optional
            ? drivername.optional()
            : drivername
                .test("len", "Nama pengemudi wajib diisi!", (val) =>
                  val ? val.length > 0 : false
                )
                .required("Nama pengemudi wajib diisi!")
          : drivername.notRequired(),
      driverPhoneNumber:
        statusDriverPhoneNumber && statusDriverPhoneNumber.active
          ? statusDriverPhoneNumber.optional
            ? driverPhoneNumber
                .optional()
                .min(10, "Nomor hp pengemudi minimal 10 karakter")
                .max(15, "Nomor hp pengemudi maksimal 15 karakter")
                .nullable()
                .transform((value) => (!!value ? value : null))
            : driverPhoneNumber
                .test("len", "Nomor hp pengemudi wajib diisi!", (val) =>
                  val ? val.length > 0 : false
                )
                .required("Nomor hp pengemudi wajib diisi!")
                .min(10, "Nomor hp pengemudi minimal 10 karakter")
                .max(15, "Nomor hp pengemudi maksimal 15 karakter")
          : driverPhoneNumber.notRequired(),
      materialId:
        statusMaterial && statusMaterial.active
          ? statusMaterial.optional
            ? materialId.optional()
            : materialId.required("Jenis material wajib diisi!")
          : materialId.notRequired(),
      ponum:
        statusPo && statusPo.active
          ? statusPo.optional
            ? ponum.optional()
            : ponum.required("Nomor PO wajib diisi!")
          : ponum.notRequired(),
      deliveryNote:
        statusDeliveryNote && statusDeliveryNote.active
          ? statusDeliveryNote.optional
            ? deliveryNote.optional()
            : deliveryNote
                .test("len", "Delivery note wajib diisi!", (val) =>
                  val ? val.length > 0 : false
                )
                .required("Delivery note wajib diisi!")
          : deliveryNote.notRequired(),
      shipmentNumber:
        statusShipmentNumber && statusShipmentNumber.active
          ? statusShipmentNumber.optional
            ? shipmentNumber.optional()
            : shipmentNumber
                .test("len", "Shipment No. wajib diisi!", (val) =>
                  val ? val.length > 0 : false
                )
                .required("Shipment No. wajib diisi!")
          : shipmentNumber.notRequired(),
      container:
        statusContainer && statusContainer.active
          ? statusContainer.optional
            ? container.optional()
            : container
                .test("len", "Nomor container wajib diisi!", (val) =>
                  val ? val.length > 0 : false
                )
                .required("Nomor container wajib diisi!")
          : container.notRequired(),
      images:
        statusPhoto && statusPhoto.active
          ? statusPhoto.optional
            ? images.optional()
            : images
                .test("len", "Wajib mengunggah foto!", (val) =>
                  val ? val.length > 0 : false
                )
                .required("Wajib mengisi foto")
          : images.notRequired(),
    });
    return {
      schema,
      isLoading,
    };
  }, [channel, isLoading]);
};

export default useSchemaUpdateWalkinReservation;
