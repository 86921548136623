import React, { Fragment, useCallback } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import { format, parse, startOfDay } from "date-fns";
import id from "date-fns/locale/id";
import qrcode from "qrcode";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { COLORS } from "../assets/theme";
import PageContainer from "../components/PageContainer/PageContainer";
import Header from "../components/atoms/Header";
import useUsertype from "../hooks/auth/useUsertype";
import {
  useCancelReservationMutation,
  useGetReservationListQuery,
} from "../redux/ReservationStore/reservationstoreAPI";
import LoadingItem from "../components/atoms/LoadingContainer/LoadingItem";
import { normalDateFormat } from "../types/dateformat";
import TicketPDFDoc from "../components/atoms/TicketPDFDoc/TicketPDFDoc";
import CustomModal from "../components/atoms/Modals/CustomModal";
import useButtonStyles from "../hooks/styles/useButtonStyles";
import EditReservationModal from "../components/atoms/Modals/EditReservationModal";
import useModalStyles from "../hooks/styles/useModalStyles";
import useSpecificChannel from "../hooks/channels/useSpecificChannel";
import { BaseIcon } from "../assets/Icons";
import { shareIcon_SVG } from "../assets/Icons/svg";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ReactSimpleImageViewer from "react-simple-image-viewer";

interface Props {}

const useStyles = createUseStyles(() => {
  return {
    container: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    dropdownToggleBtn: {
      border: 0,
      backgroundColor: "transparent",
      color: COLORS.black_1,
      "&:hover": {
        color: COLORS.white,
      },
      "&:focus": {},
    },
    dropdownMenuItem: {
      "& button": {
        border: 0,
        backgroundColor: "white",
        "&:hover": {
          opacity: 0.7,
          backgroundColor: "red",
          color: "white",
        },
      },
    },

    buttonContainer: {},
    detailReservationContainer: {
      display: "flex",
      flexDirection: "column",
    },
    table: {
      borderCollapse: "separate",
      borderSpacing: "0.8rem 0.2rem",
      marginTop: 16,
      "& tbody > tr > td": {
        fontSize: 13,
        fontWeight: 600,
        paddingBottom: 8,
        verticalAlign: "text-top",
      },
      "& tbody > tr > td:last-child": {
        wordBreak: "break-word",
      },
      "& tbody > tr > td:first-child": {
        width: 120,
        wordBreak: "normal",
      },
    },

    qrImage: {},
    textReservationDetail: {
      color: "black",
      fontFamily: "Raleway",
      fontSize: "12px",
    },
    primaryButton: {
      textDecoration: "none",
      fontWeight: 600,
      width: "100%",
      boxSizing: "border-box",
      border: 0,
      background: COLORS.gradientBlueGreen_1,
      borderRadius: ".8rem",
      color: "white",
      textAlign: "center",
      fontSize: "1.5rem",
      padding: "1.8rem 0",
      letterSpacing: "0.02rem",
      textTransform: "uppercase",
      "&:hover": {
        opacity: 0.7,
        color: "white",
      },
    },
    secondaryButton: {
      display: "block",
      cursor: "pointer",
      border: `3px solid ${COLORS.blue_1}`,
      textDecoration: "none",
      fontWeight: 600,
      width: "100%",
      textAlign: "center",
      fontSize: "1.2rem",
      padding: "1.6rem 0",
      letterSpacing: "0.02rem",
      textTransform: "uppercase",
      color: COLORS.blue_1,
      borderRadius: ".8rem",
      backgroundColor: "white",
      "&:disabled": {
        backgroundColor: COLORS.gray_1,
        color: COLORS.gray_2,
        "& > svg": {
          fill: COLORS.gray_2,
        },
      },
      "&:hover": {
        backgroundColor: COLORS.blue_1,
        color: COLORS.white,
        "& > svg": {
          fill: COLORS.white,
        },
      },
    },
    bottomBtnContainer: {
      backgroundColor: "white",
      position: "relative",
      left: 0,
      right: 0,
      padding: "1.2rem 1rem",
      display: "flex",
      flexDirection: "column",
      gap: "1.6rem",
    },
    modal: {
      "&>*": {
        padding: "2rem",
      },
    },
    modalHeader: {
      borderBottom: 0,
      "&>h5": {
        lineHeight: "14.09px",
        fontWeight: 600,
        fontSize: "1.4rem",
      },
    },
  };
});

const DetailReservationPage: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const btn_classes = useButtonStyles();
  const modal_classes = useModalStyles();
  const navigate = useNavigate();
  const params = useParams<"ticketId">();
  const ticketId = params.ticketId!;
  const [isLoadingQR, setIsLoadingQR] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showImagesCarousel, setShowImagesCarousel] =
    React.useState<boolean>(false);
  const [isLoadingShareButtonLink, setIsLoadingShareButtonLink] =
    React.useState<boolean>(false);
  const [cancelReservation] = useCancelReservationMutation();
  const usertype = useUsertype();
  const [qr, setQr] = React.useState<string>();
  const [currentImage, setCurrentImage] = React.useState<number>(0);
  const {
    data,
    isLoading: isLoadingList,
    isFetching: isFetchingList,
    refetch,
  } = useGetReservationListQuery(
    { id: ticketId ? parseInt(ticketId) : undefined },
    { skip: !ticketId }
  );

  const pickedTicket = React.useMemo(() => {
    if (data && ticketId) {
      return data.entities.find(({ id }) => id === parseInt(ticketId));
    }
    return undefined;
  }, [data, ticketId]);

  React.useEffect(() => {
    const getQrCodeAsync = async (qr: string) => {
      await qrcode
        .toDataURL(qr, { width: 300 })
        .then((val) => {
          setQr(val);
        })
        .finally(() => {
          setIsLoadingQR(false);
        });
    };
    if (pickedTicket) {
      setIsLoadingQR(true);
      let qr = pickedTicket.qrid;
      if (qr) {
        getQrCodeAsync(qr);
      } else {
        setIsLoadingQR(false);
        setQr(undefined);
      }
    }
  }, [pickedTicket]);

  const checkIfNotPassedDate = () => {
    if (!pickedTicket) return;
    const maxDate = parse(
      pickedTicket?.schedule.to,
      "HH:mm:ss",
      new Date(pickedTicket?.date)
    );
    const isNotPassedDate = new Date().getTime() < maxDate.getTime();
    return isNotPassedDate;
  };

  const handleShareDetail = useCallback(async () => {
    setIsLoadingShareButtonLink(true);
    if (navigator.share) {
      setTimeout(async () => {
        try {
          await navigator
            .share({
              title: "Detail Reservasi",
              url: `${
                process.env.REACT_APP_BASIC_URL ?? ""
              }#/view/reservation/${pickedTicket?.qrid ?? ""}`,
            })
            .then(() => {
              setIsLoadingShareButtonLink(false);
            })
            .catch(() => {
              setIsLoadingShareButtonLink(false);
            });
        } catch (error) {
          console.error(error);
        }
      }, 300);
    }
  }, [ticketId, pickedTicket]);

  const { channel } = useSpecificChannel(pickedTicket?.channelId ?? -1, {
    skip: typeof pickedTicket === "undefined",
  });

  let isPast =
    pickedTicket &&
    new Date(pickedTicket?.date).getTime() < startOfDay(new Date()).getTime();

  const rightButton =
    usertype === "officer" || usertype === "user" ? (
      <UncontrolledDropdown style={{ alignSelf: "center", flex: 1 }}>
        <DropdownToggle className={classes.dropdownToggleBtn}>
          <BiDotsVerticalRounded size={16} />
        </DropdownToggle>
        <DropdownMenu end className={classes.dropdownMenuItem}>
          <DropdownItem onClick={() => setShowDeleteModal(true)}>
            Batalkan Reservasi
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    ) : undefined;

  // const isTrue = usertype === "officer"

  const isLoading = isLoadingList || isFetchingList;

  return (
    <PageContainer
      headerNavigationContent={
        <div style={{ minHeight: "100vh", width: "100%", padding: "0, 16px" }}>
          <LoadingItem isLoading={isLoading} />
          {
            <>
              <Header
                backButtonOnClick={() => navigate(-1)}
                showBackButton={true}
                rightButton={
                  !isPast &&
                  pickedTicket?.active &&
                  pickedTicket?.statusTruck === "reserved" &&
                  checkIfNotPassedDate() &&
                  rightButton
                }
                title={`Reservasi`}
              />
              {pickedTicket && (
                <div className={classes.detailReservationContainer}>
                  {showImagesCarousel && (
                    <ReactSimpleImageViewer
                      src={pickedTicket.photo.map(
                        (item) => `${process.env.REACT_APP_BASIC_URL}${item}`
                      )}
                      currentIndex={currentImage}
                      onClose={() => setShowImagesCarousel(false)}
                      disableScroll
                      backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                      }}
                      closeOnClickOutside
                    />
                  )}
                  <table className={classes.table}>
                    <colgroup>
                      <col />
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      {
                        <tr>
                          <td>Vendor</td>
                          <td>:</td>
                          <td>
                            {pickedTicket.vendcode !== null &&
                            pickedTicket.vendor?.name !== undefined
                              ? `${pickedTicket.vendcode} - ${pickedTicket.vendor?.name}`
                              : "-"}
                          </td>
                        </tr>
                      }
                      <tr>
                        <td>Tanggal</td>
                        <td>:</td>
                        <td>
                          {format(
                            new Date(pickedTicket.date),
                            normalDateFormat,
                            {
                              locale: id,
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Pukul</td>
                        <td>:</td>
                        <td>{`${pickedTicket.schedule.from} - ${pickedTicket.schedule.to}`}</td>
                      </tr>
                      <tr>
                        <td>Channel</td>
                        <td>:</td>
                        <td>{pickedTicket.channel.description ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>No Kendaraan</td>
                        <td>:</td>
                        <td>{pickedTicket?.nopol ?? "-"}</td>
                      </tr>
                      {channel?.statusDriverName.active && (
                        <tr>
                          <td>Pengemudi</td>
                          <td>:</td>
                          <td>
                            {pickedTicket.drivername
                              ? pickedTicket.drivername
                              : "-"}
                          </td>
                        </tr>
                      )}
                      {channel?.statusDriverPhoneNumber?.active && (
                        <tr>
                          <td>Nomor HP Pengemudi</td>
                          <td>:</td>
                          <td>{pickedTicket.driverPhoneNumber ?? "-"}</td>
                        </tr>
                      )}
                      {channel?.statusMaterial.active && (
                        <tr>
                          <td>Jenis Barang</td>
                          <td>:</td>
                          <td>{pickedTicket.material?.description ?? "-"}</td>
                        </tr>
                      )}
                      {channel?.statusPo.active && (
                        <>
                          <tr>
                            <td>PO</td>
                            <td>:</td>
                            <td>{pickedTicket?.ponum ?? "-"}</td>
                          </tr>
                          <tr>
                            <td>Line</td>
                            <td>:</td>
                            <td>{pickedTicket?.poline ?? "-"}</td>
                          </tr>
                        </>
                      )}
                      {channel?.statusDeliveryNote.active && (
                        <tr>
                          <td>Delivery Note</td>
                          <td>:</td>
                          <td>{pickedTicket?.deliveryNote ?? "-"}</td>
                        </tr>
                      )}
                      {channel?.statusShipmentNumber.active && (
                        <tr>
                          <td>Shipment No.</td>
                          <td>:</td>
                          <td>{pickedTicket?.shipmentNumber ?? "-"}</td>
                        </tr>
                      )}
                      {channel?.statusContainer.active && (
                        <tr>
                          <td>No Container</td>
                          <td>:</td>
                          <td>{pickedTicket?.container?.container ?? "-"}</td>
                        </tr>
                      )}
                      {channel?.statusPhoto.active && (
                        <tr>
                          <td>Foto</td>
                          <td>:</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 8,
                              }}
                            >
                              {pickedTicket.photo &&
                              pickedTicket.photo.length > 0 ? (
                                <span>
                                  {pickedTicket.photo.length} foto{" "}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      color: COLORS.blue_1,
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setShowImagesCarousel(!showImagesCarousel)
                                    }
                                  >
                                    (Lihat Foto)
                                  </span>
                                </span>
                              ) : (
                                "-"
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {qr ? (
                      <img className={classes.qrImage} alt="qr-code" src={qr} />
                    ) : isLoadingQR ? (
                      <p style={{ margin: "3rem 0" }}>Sedang memuat QR...</p>
                    ) : (
                      <p style={{ margin: "3rem 0" }}>Gagal memuat QR Code</p>
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  alignContent: "space-between",
                }}
              ></div>
              {qr && !!channel && pickedTicket && (
                <Fragment>
                  <div className={classes.bottomBtnContainer}>
                    <div
                      style={{
                        display: "flex",
                        gap: 16,
                        justifyContent: "space-between",
                      }}
                    >
                      {pickedTicket.active &&
                        pickedTicket.statusTruck === "reserved" && (
                          <button
                            className={classes.secondaryButton}
                            onClick={() => setShowEditModal(true)}
                          >
                            Ubah
                          </button>
                        )}
                      <button
                        onClick={handleShareDetail}
                        className={classes.secondaryButton}
                        style={{
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                        disabled={isLoadingShareButtonLink}
                      >
                        {!isLoadingShareButtonLink ? (
                          <>
                            <BaseIcon
                              src={shareIcon_SVG}
                              width={16}
                              title="shareIcon"
                              height={16}
                              fill={COLORS.blue_1}
                            />
                            <span style={{ marginLeft: 16 }}>Bagikan</span>
                          </>
                        ) : (
                          <span style={{ marginLeft: 16 }}>Loading...</span>
                        )}
                      </button>
                    </div>

                    <PDFDownloadLink
                      fileName="Ticket.pdf"
                      className={classes.primaryButton}
                      document={
                        <TicketPDFDoc
                          channel={channel}
                          date={pickedTicket.date}
                          driverName={pickedTicket.drivername}
                          driverPhoneNumber={
                            pickedTicket?.driverPhoneNumber ?? "-"
                          }
                          deliveryNote={pickedTicket?.deliveryNote ?? "-"}
                          shipmentNumber={pickedTicket?.shipmentNumber ?? "-"}
                          container={pickedTicket?.container?.container ?? "-"}
                          materialName={
                            pickedTicket.material?.description ?? "-"
                          }
                          approverPosition={0}
                          vendor={pickedTicket?.vendor ?? undefined}
                          nopol={pickedTicket?.nopol ?? "-"}
                          poNum={pickedTicket?.ponum ?? "-"}
                          poLine={pickedTicket?.poline ?? undefined}
                          startTime={pickedTicket.schedule.from}
                          endTime={pickedTicket.schedule.to}
                          qr={qr}
                          isWalkin={false}
                          photo={pickedTicket?.photo}
                        />
                      }
                    >
                      {({ loading }) => {
                        return loading ? "Loading..." : "SIMPAN SEBAGAI PDF";
                      }}
                    </PDFDownloadLink>
                  </div>
                </Fragment>
              )}
              <LoadingItem isLoading={isLoading} />
              {/* DELETE MODAL */}
              <CustomModal
                className={modal_classes.modal}
                toggle={() => setShowDeleteModal((prev) => !prev)}
                isOpen={showDeleteModal}
              >
                <ModalHeader
                  className={modal_classes.modalHeader}
                  toggle={() => setShowDeleteModal((prev) => !prev)}
                >
                  BATALKAN RESERVASI
                </ModalHeader>
                <ModalBody className="body">
                  <p style={{ marginBottom: "3rem" }}>
                    Apakah anda ingin membatalkan Reservasi yang telah dibuat?
                  </p>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <button
                      className={[btn_classes.button, "danger"].join(" ")}
                      onClick={() => {
                        pickedTicket &&
                          cancelReservation({ id: pickedTicket!.id })
                            .unwrap()
                            .then(() => {
                              toast.success("Reservasi berhasil dibatalkan");
                              navigate(-1);
                            })
                            .catch((e) => {
                              toast.error("Reservasi gagal dibatalkan");
                            });
                      }}
                    >
                      Batalkan
                    </button>
                    <button
                      className={[btn_classes.button, "normal"].join(" ")}
                      onClick={() => setShowDeleteModal(false)}
                      style={{ color: COLORS.white }}
                    >
                      Kembali
                    </button>
                  </div>
                </ModalBody>
              </CustomModal>
              {/* EDIT MODAL */}
              {pickedTicket && showEditModal && (
                <EditReservationModal
                  toggle={() => setShowEditModal((prev) => !prev)}
                  isOpen={showEditModal}
                  ticket={pickedTicket}
                  onSuccess={() => {
                    refetch();
                  }}
                />
              )}
            </>
          }
        </div>
      }
      className={classes.container}
      withBackground
    ></PageContainer>
  );
};

export default DetailReservationPage;
