import { COLORS } from "../../../assets/theme";
import { convertToEncodedURL } from "../../../tools";
import { BackendDataShape } from "../../../types";
import { ADock, ADockWithColor } from "../../../types/channel/dock";
import {
  AWarehouse,
  AWarehouseWithColor,
} from "../../../types/channel/warehouse";
import { channelstoreAPI } from "../channelstoreAPI";

export const warehouseDockStoreAPI = channelstoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAllDock: builder.query<ADockWithColor[], { channelId: number }>({
        query: ({ channelId }) => {
          // let params = "";
          // if (obj) {
          //     params = convertToEncodedURL(obj);
          //     params = params.length ? "?" + params : "";
          // }
          return {
            url: `/${channelId}/warehouse/dock/all`,
            method: "GET",
          };
        },
        providesTags: ["DockList"],
        transformResponse: (res: BackendDataShape<ADockWithColor[]>) => {
          const colors = [
            COLORS.orange_1,
            COLORS.purple,
            COLORS.green,
            COLORS.brown,
            COLORS.green_2,
            COLORS.yellow_1_rgb,
            COLORS.blue_2_rgb,
            COLORS.blue_3_rgb,
            COLORS.green_3,
            COLORS.purple_1_rgb,
            COLORS.green_3_rgb,
          ];
          return res.data.map((val, idx) => {
            return {
              ...val,
              color: colors[idx % colors.length],
            };
          });
        },
      }),
      getDock: builder.query<
        ADockWithColor[],
        { channelId: number; warehouseId: number }
      >({
        query: ({ channelId, warehouseId }) => {
          // let params = "";
          // if (obj) {
          //     params = convertToEncodedURL(obj);
          //     params = params.length ? "?" + params : "";
          // }
          return {
            url: `/${channelId}/warehouse/${warehouseId}/dock`,
            method: "GET",
          };
        },
        providesTags: ["DockList"],
        transformResponse: (res: BackendDataShape<ADockWithColor[]>) => {
          const colors = [
            COLORS.orange_1,
            COLORS.purple,
            COLORS.green,
            COLORS.brown,
            COLORS.green_2,
            COLORS.yellow_1_rgb,
            COLORS.blue_2_rgb,
            COLORS.blue_3_rgb,
            COLORS.green_3,
            COLORS.purple_1_rgb,
            COLORS.green_3_rgb,
          ];
          return res.data.map((val, idx) => {
            return {
              ...val,
              color: colors[idx % colors.length],
            };
          });
        },
      }),
      createDock: builder.mutation<
        unknown,
        { channelId: number; warehouseId: number; data: Array<Partial<ADock>> }
      >({
        query: ({ channelId, warehouseId, data }) => {
          return {
            url: `/${channelId}/warehouse/${warehouseId}/dock`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["DockList"],
      }),
      updateDock: builder.mutation<
        unknown,
        { channelId: number; warehouseId: number; data: Array<Partial<ADock>> }
      >({
        query: ({ channelId, warehouseId, data }) => {
          return {
            url: `/${channelId}/warehouse/${warehouseId}/dock`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["DockList"],
      }),
      deleteWarehouseDock: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * company Id
           */
          channelId: number;
          warehouseId: number;
          dockId: number;
        }
      >({
        query: ({ channelId, warehouseId, dockId }) => {
          return {
            url: `/${channelId}/warehouse/${warehouseId}/dock/${dockId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["DockList"],
      }),
    };
  },
});

export const {
  useGetDockQuery,
  useGetAllDockQuery,
  useCreateDockMutation,
  useUpdateDockMutation,
  useDeleteWarehouseDockMutation,
  util: { resetApiState: resetDockStoreAPI },
} = warehouseDockStoreAPI;
